<template>
  <v-container fluid>
    <loading transition="fade" :can-cancel="false" :is-full-page="false" loader="dots" color="#efb639" :opacity="0.9">
    </loading>

    <v-row class="pa-5 justify-center">
      <v-col class="d-flex justify-center" v-for="keyB in keyBnt" :key="keyB.id" cols="12" md="2">
        <v-btn width="100%" color="primary" block> {{ keyB.keyName }} </v-btn>
      </v-col>
    </v-row>

    <v-card class="my-5" outlined elevation="1">
      <v-card-title style="background-color: #e1e5e9">Mes chiffres clés</v-card-title>
      <br />
      <v-row class="pa-5">
        <v-col class="d-flex" v-for="keyP in keyNumber" :key="keyP.id" cols="12" md="3">
          <v-card class="mx-auto" width="100%" elevation="6">
            <v-card-text class="pa-5">
              <h1 class="text-center">{{ keyP.keyTitle }}</h1>
              <p class="text-h7 text-center" style="color: #fcc03c">{{ keyP.keyPrincipal }}</p>
              <p class="text-h7 text--primary text-center">
                {{ keyP.description }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-row>
      <v-col cols="12" md="5">
        <v-card class="my-10" outlined elevation="1">
          <v-card-title style="background-color: #e1e5e9">Mes attestations en cours</v-card-title>
          <v-row class="pa-5">
            <v-col class="d-flex" v-for="cert in certificate" :key="cert.id" cols="12" md="6">
              <v-card class="mx-auto pa-5" width="90%" elevation="6">
                <v-card-text>
                  <p class="text-h5 text-center" style="color: #fcc03c">{{ cert.certif }}</p>
                  <p class="text-h7 text--primary text-center">
                    {{ cert.name }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="7">
        <v-card class="my-10" width="100%" outlined elevation="1">
          <v-card-title style="background-color: #e1e5e9">Coordonnées entreprise</v-card-title>
          <v-row class="pa-6">
            <v-col class="d-flex pa-5" v-for="info in information" :key="info.id" cols="12" md="4">
              <v-card width="100%" elevation="6">
                <v-card-text>
                  <h3 class="text-center">{{ info.name }}</h3>
                  <p class="text-h7 text-center" style="color: #fcc03c">{{ info.adresse }}</p>
                  <p class="text-h7 text--primary text-center">
                    {{ info.type }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-col cols="12" md="12">
      <v-card class="my-5" outlined elevation="1">
        <v-card-title style="background-color: #e1e5e9">Mes interlocuteurs C2T ASSURANCES</v-card-title>
        <br />
        <v-row class="pa-5">
          <v-col class="d-flex" v-for="inter in interlocutors" :key="inter.id" cols="12" md="3">
            <v-card width="100%" elevation="6">
              <v-card-text>
                <v-img style="margin-left: auto; margin-right: auto" width="100px"> </v-img>
                <h2 class="text-center">{{ inter.name }}</h2>
                <p class="text-center">
                  <v-icon left>mdi-email</v-icon><a :href="`mailto:${inter.mail}`">{{ inter.mail }}</a>
                </p>
                <p class="text--primary text-center">{{ inter.phone }}</p>
                <p class="text-center" style="color: #1697f6">{{ inter.role }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-col>
      <v-card class="my-5" outlined elevation="1">
        <v-card-title class="pa-0" style="background-color: #e1e5e9">
          <v-col cols="12" md="9"> Installateurs </v-col>
          <v-col cols="12" md="3">
            <v-btn class="cols=12 md=2" color="primary">
              <v-icon left> mdi-account-multiple-plus </v-icon>
              Ajouter Installateur</v-btn
            >
          </v-col>
        </v-card-title>
        <br />
        <v-row>
          <v-col v-for="install in keyInstall" :key="install.id" cols="12" md="3">
            <v-card class="mx-auto" width="90%" elevation="6">
              <v-card-text class="pa-5">
                <p class="text-h6 text-center" style="color: #fcc03c">{{ install.keyName }}</p>
                <p class="text-h7 text--primary text-center">CA N-1 : {{ install.caN1 }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <br />
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import Vue from "vue"
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import "vue-loading-overlay/dist/vue-loading.css"

Vue.use(Toast, { maxToasts: 2, newestOnTop: true })

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
  data: () => ({
    agencyName: null,
    load: false,
    ipError: false,
    ip: "",
    ipLocation: "",
    ipInfo: [],
    states: ["administrateur", "collaborateur"],
    /*
    headers: [
      { text: "", value: "phoneNumber", sortable: false },
      {
        text: "COLLABORATEUR",
        align: "start",
        sortable: false,
        value: "completeName",
      },
      { text: "QUALITÉ", value: "statut" },
      { text: "EMAIL", value: "email" },
      { text: "DERNIÈRE CONNEXION", value: "lastConnection" },
      { text: "ADRESSE IP", value: "lastIPConnected" },
      { text: "ACTIONS", value: "actions", sortable: false },
    ], */
    interlocutors: [
      {
        id: 1,
        img: "roman.png",
        name: "FRICHOT ROMAN",
        mail: "roman.frichot@eole-assurance.bzh",
        phone: "02.99.54.XX.XX",
        role: "MASTER CYBER SECURITER",
      },
      {
        id: 2,
        img: "roro.jpg",
        name: "MATHIS GRANNEC",
        mail: "mathis.grannec@eole-assurance.bzh",
        phone: "02.98.16.XX.XX",
        role: "BSI CYBER SECURITER",
      },
      {
        id: 3,
        img: "roro.jpg",
        name: "DAVID AUDREN",
        mail: "david.audren@eole-assurance.bzh",
        phone: "02.98.48.XX.XX",
        role: "MANAGER",
      },
    ],
    information: [
      {
        id: 1,
        name: "TARTAMPION DULAC",
        adresse: "tartampion@dulac.fr",
        type: "Interlocuteur Collectives",
      },
      {
        id: 2,
        name: "EDWIGE LE DOUARIN",
        adresse: "edwige@ledouarin.fr",
        type: "Interlocuteur Dommages",
      },
      {
        id: 3,
        name: "SAS AQUATIRIS",
        adresse: "adresse postale 35480",
        type: "Adresse correspondances",
      },
    ],
    certificate: [
      {
        id: 1,
        certif: "XX",
        name: "Attestation Responsabilité civile",
      },
      {
        id: 2,
        certif: "XX",
        name: "Attestation décennale",
      },
    ],
    keyNumber: [
      {
        id: 1,
        keyTitle: "N",
        keyPrincipal: "20 213 000,56" + "€",
        description: "Tendance Chiffre d'affaires",
      },
      {
        id: 2,
        keyTitle: "N-1",
        keyPrincipal: "18 213 000,56" + "€",
        description: "Mon Chiffre d'affaires",
      },
      {
        id: 3,
        keyTitle: "6",
        keyPrincipal: "114 000,56" + "€",
        description: "Mes installateurs",
      },
      {
        id: 4,
        keyTitle: "2",
        keyPrincipal: "",
        description: "Mes contrats",
      },
    ],
    keyBnt: [
      {
        id: 1,
        keyName: "être Rappelé",
      },
      {
        id: 2,
        keyName: "Demande de RDV",
      },
      {
        id: 3,
        keyName: "Déclarer un sinistre",
      },
      {
        id: 4,
        keyName: "Gérer ma flotte",
      },
      {
        id: 5,
        keyName: "Ma prévention",
      },
    ],
    keyInstall: [
      {
        id: 1,
        keyName: "SARL LEDI",
        caN1: "19 000",
      },
      {
        id: 2,
        keyName: "SARL LOPI",
        caN1: "20 000",
      },
      {
        id: 3,
        keyName: "SARL MERV",
        caN1: "36 987",
      },
      {
        id: 4,
        keyName: "SARL POPA",
        caN1: "78 975",
      },
      {
        id: 5,
        keyName: "SARL POIO",
        caN1: "896 365",
      },
    ],
  }),

  methods: {},
}
</script>
